import React from "react";
import logo from "./logo-grups.png";
import "./App.css";

function App() {
  console.log(window.location.pathname);
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p style={{ color: "#e06410", marginBottom: 40 }}>
          Has recibido un link para acceder a un evento en Grups
        </p>
        <a
          className="App-link"
          href={`grups-app:/${window.location.pathname}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Ver evento
        </a>

        <p style={{ marginTop: 40, fontSize: "calc(6px + 2vmin)" }}>
          Si no has descargado la app, podes encontrarla{" "}
          <a
            className="App-link"
            href={`grups-app:/${window.location.pathname}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Aquí
          </a>
        </p>
      </header>
    </div>
  );
}

export default App;
